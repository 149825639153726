import React, { Component } from 'react';
import '../css/timeline.css';
import StackOverflowProfile from './inc/stackoverflow.js';
import { Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import carteVisit from './img/carte-visite-ggerard.jpg';


class Posts extends Component {
  
  
  componentDidMount() {
  window.scrollTo(0,0);
}
  render() {
    
    return (
     
        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Parcours création site Internet Nantes St Nazaire</title>
    <meta charset="UTF-8" />                <meta name="description" content="Parcours de Gaël GERARD Freelance Web dans la région de Nantes St Nazaire." />
            <meta property="og:title" content="Parcours création site Internet Nantes St Nazaire" />
              <meta property="og:image" content={carteVisit} />
            
            <meta property="og:locale" content="fr_FR" />
<meta property="og:type" content="page" />
<meta property="og:description" content="Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site Internet. Implanté à Cordemais entre Nantes et Saint Nazaire, j&#039;apporte une réponse personnalisée à vos besoins en communication sur Internet : Sites vitrine, e-commerce, référencement naturel..." />
<meta property="og:url" content="https://www.gaelgerard.com/" />
<meta property="og:site_name" content="Freelance web Nantes St Nazaire" />
<meta property="article:publisher" content="https://www.facebook.com/gaelgerardwebnantes/" />
<meta property="og:image" content={carteVisit} />
<meta property="og:image:secure_url" content={carteVisit} />
<meta name="twitter:card" content="summary" />
<meta name="twitter:description" content="Faîtes réaliser votre site Internet par un professionnel de la région Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site [&hellip;]" />
<meta name="twitter:title" content="Parcours - Freelance web Nantes St Nazaire" />
<meta name="twitter:site" content="@gaelgerard" />
<meta name="twitter:image" content={carteVisit} />
<meta name="twitter:creator" content="@gaelgerard" />
          </Helmet>
            <div className="row">

            

            	<section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">

                	<article role="pge-title-content">

                    	<header>

                        	<h1><span>Expert Web</span> depuis près de 20&nbsp;ans.</h1>
                        <p>Parcourez ma Timeline animée ci-dessous et cliquez sur les boutons <span><i className="fa fa-check-circle-o" aria-hidden="true"></i>
</span> pour dérouler les détails.</p>

                        </header>


                    </article>

                </section>

                

                <section className="col-xs-12 col-sm-6 col-md-6 col-lg-6">

                	<article className="about-content row">

                    	<p className="col-xs-8 col-sm-8 col-md-8 col-lg-8">Chef de projet, webmaster, intégrateur web, développeur Wordpress, je vous conseille et vous accompagne dans la conception et la réalisation de votre communication digitale sur le web et les mobiles.</p>
<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><img src="/img/gaelgerard_agenceinteractive.jpg" alt="Freelance web Nantes St Nazaire" className="portrait"/></div>
                                            <div className="col-xs-12"><StackOverflowProfile userId="3623080"/></div>
</article>

                </section>

                

                <div className="clearfix"></div>

                


                    	<section className="col-xs-12 col-sm-1 col-md-1 col-lg-1">
                        </section>
                    	<section className="w-100">

                        <ul className="timeline">
	<li className="event">
		<input type="radio" name="tl-group" defaultChecked/>
		<label></label>
		<div className="thumb user-4"><span>Depuis 2013</span></div>
		<div className="content-perspective">
			<div className="content">
				<div className="content-inner">
				<h3>Freelance Conseil et création en Communication digitale</h3>
				<p>Accompagnement dans la conception et la réalisation de communication digitale sur le web et les mobiles.</p>
				
				<p>- Pour les agences : sous-traitance, outsourcing en gestion de projet, intégration HTML/CSS, développement Front End, Responsive design</p>
				
				<p>- Pour les TPE, Artisans, Commerçants, PME et entreprises : Gestion de plan de communication en ligne et hors ligne, réalisation de site internet et mobile</p>
				
				</div>
			</div>
		</div>
	</li>
	<li className="event"><input type="radio" name="tl-group" />
<label></label>
<div className="thumb user-6"><span>2008 à 2013
</span></div>
<div className="content-perspective">
<div className="content">
<div className="content-inner">
<h3>Chef de projet Digital ALTAVIA Nantes</h3>
<p>Mon rôle au sein de l'équipe web :</p>
<p>- Gestion de projets<br/>
- Intégration XHTML / CSS<br/>
- Référencement<br/>
- Campagnes emailing</p>

<p>Mon rôle en tant que chef de projet internet</p>

<p>Gestion de projets internet :</p>
<p>- Préconisation<br/>
- Cahier des charges<br/>
- Chiffrage et budget<br/>
- Relation client</p>

</div>
</div>
</div></li>
	<li className="event"><input type="radio" name="tl-group" />
<label></label>
<div className="thumb user-3"><span>2007 à 2008
</span></div>
<div className="content-perspective">
<div className="content">
<div className="content-inner">
<h3>Chef de projet Internet ACCES WEB INFOCOB</h3>
<p>Gestion des projets web de l’avant-vente jusqu’au référencement.</p>
<p>En contact direct avec les clients web et GRC, élaboration des recommandations techniques et stratégiques et gestion de leurs mises en oeuvre :</p>
<p>- Participation aux recommandations avant-vente<br/>
- Rédaction de cahiers des charges<br/>
- Pilotage et suivi des projets<br/>
- Optimisation de sites pour le référencement<br/>
- Référencement et suivi de positionnement.</p>

</div>
</div>
</div></li>
	<li className="event"><input type="radio" name="tl-group" />
<label></label>
<div className="thumb user-5"><span>2006 À 2007
</span></div>
<div className="content-perspective">
<div className="content">
<div className="content-inner">
<h3>Chef de projet Communication Institut de la Pierre Attelée</h3>

<p>Fondées sur les bases d'une Association franco-sino-russe, l'Institut de la Pierre Attelée et la société Pierre Attelée Gestion, participent conjointement au rapprochement économique et culturel de ces pays.</p>
<p>Mes rôles dans ces structures sont multiples ;</p>
<p>- Chef de projet internet, webmaster,<br/>
- Référent informatique et communication,<br/>
- Organisateur événementiels (conférences, séminaires, formations),<br/>
- Intervenant nouvelles technologies sur les formations longues,<br/>
- Conseil aux entreprises.</p>

<p>Ces fonctions s'inscrivent dans mon parcours de généraliste de la Communication.</p>

</div>
</div>
</div></li>
	<li className="event"><input type="radio" name="tl-group" />
<label></label>
<div className="thumb user-2"><span>2002 à 2006
</span></div>
<div className="content-perspective">
<div className="content">
<div className="content-inner">
<h3>Freelance Communication globale</h3>
<p>- Communication audiovisuelle
- Communication institutionnelle
- Communication web
- Communication événementielle
- Marketing
- Hotline
- Conseil aux entreprises et particuliers</p>

</div>
</div>
</div></li>
	<li className="event"><input type="radio" name="tl-group" />
<label></label>
<div className="thumb user-7"><span>2000 à 2002
</span></div>
<div className="content-perspective">
<div className="content">
<div className="content-inner">
<h3>Licence et Maîtrise en Communication Université de Nantes</h3>
<p>Spécialité « rédaction et conception de contenu multimédia »</p>

<p>Production rédactionnelle et audiovisuelle pour les nouveaux supports
numériques</p>

<p>Maîtrise technique des différents outils logiciels et matériels numérique</p>

</div>
</div>
</div></li>
</ul>

                
</section>
                

            </div>

        </main>

    );
  }
}
export default Posts